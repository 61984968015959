import localStorage from '@locmod/local-storage'
import { isAddress } from 'viem'
import { events } from '@locmod/event-aggregator'
import { restApi } from 'helpers'
import { getProfileMeApiData } from 'hooks/useProfileMe'
import { AxiosError } from 'axios'

export type AuthStorageData = {
  token: string | undefined
  address: Address | undefined
}

export type AuthState = {
  refCode: string | undefined
  token: string | undefined
  address: Address | undefined
  isInited: boolean
}

const storageName = 'auth'
export const authEventName = 'token-updated'

const savedData = (__CLIENT__ ? localStorage.getItem<AuthStorageData>(storageName) || {} : {}) as AuthStorageData

let value: AuthState = {
  token: typeof savedData.token === 'string' && savedData.token.length > 8 ? savedData.token : undefined,
  address: isAddress(savedData.address || '') ? savedData.address : undefined,
  refCode: __CLIENT__ ? new URLSearchParams(window.location.search).get('ref') || undefined : undefined,
  isInited: false,
}

let listeners: Function[] = []

export const authStore = {
  set({ token, address }: { token?: string | undefined; address: Address | undefined }) {
    if (__SERVER__) {
      return
    }

    if (value.address && value.address === address && value.token) {
      return
    }

    value = {
      ...value,
      token,
      address,
    }

    emitChange()
  },
  init() {
    console.log('=== init')
    if (value.token) {
      getProfileMeApiData()
        .then((data) => {
          if (data) {
            value = {
              ...value,
              isInited: true,
            }

            emitChange()
          }
        })
        .catch((error) => {
          if (error instanceof AxiosError && error.status === 401) {
            value = {
              ...value,
              token: undefined,
              isInited: true,
            }

            emitChange()
          }
        })
    } else {
      value = {
        ...value,
        isInited: true,
      }

      emitChange()
    }
  },
  logout() {
    const cleanUp = () => {
      value = {
        ...value,
        token: undefined,
        address: undefined,
      }

      emitChange()
    }

    if (value.token) {
      restApi.delete('/profile/logout')
      setTimeout(cleanUp)
    } else {
      cleanUp()
    }
  },
  subscribe(listener: Function) {
    listeners = [...listeners, listener]

    return () => {
      listeners = listeners.filter((l) => l !== listener)
    }
  },
  getSnapshot() {
    return value
  },
}

function emitChange() {
  if (__CLIENT__) {
    if (value.token || value.address) {
      localStorage.setItem(storageName, value)
    } else {
      localStorage.removeItem(storageName)
    }
  }

  events.dispatch(authEventName)

  for (let listener of listeners) {
    listener()
  }
}
