'use client'

import { createContext, useContext, useEffect, useSyncExternalStore } from 'react'
import { useAccount } from 'wagmi'
import { openModal, useModalOpenState } from '@locmod/modal'
import { authStore, type AuthState } from './store'

type AuthContextValue = AuthState & {
  notVerifiedAddress: Address | undefined
  isReady: boolean
}

export const AuthContext = createContext<AuthContextValue>({
  ...authStore.getSnapshot(),
  isReady: false,
  notVerifiedAddress: undefined,
})
export const useAuthContext = () => useContext(AuthContext)

const AuthProvider: React.CFC = (props) => {
  const { children } = props

  const account = useAccount()
  const { isInited, address, token, refCode } = useSyncExternalStore(
    authStore.subscribe,
    authStore.getSnapshot,
    authStore.getSnapshot,
  )
  const isSiweModalOpen = useModalOpenState('SiweModal')

  useEffect(() => {
    if (account.isConnecting || account.isReconnecting) {
      return
    }

    if (account.isDisconnected || !account.address) {
      authStore.logout()
    }

    if (account.address !== address) {
      authStore.set({ token: undefined, address: account.address })
    }
  }, [account.status, account.address])

  const isReadyToInit =
    !account.isReconnecting && !account.isConnecting && ((!account.address && !address) || account.address === address)

  const { address: accountAddress, status, isReconnecting, isConnecting } = account
  console.log('=== isReadyToInit', isReadyToInit, {
    address,
    accountAddress,
    isConnecting,
    isReconnecting,
    status,
    token,
    isInited,
  })

  const isReady = Boolean(isReadyToInit && isInited && (!address || token))

  useEffect(() => {
    if (!isReadyToInit) {
      return
    }

    if (!isInited) {
      authStore.init()
    } else if (address && !token && !isSiweModalOpen) {
      openModal('SiweModal')
    }
  }, [isReadyToInit, isInited, address, token])

  const value: AuthContextValue = {
    address: isReady ? address : undefined,
    notVerifiedAddress: address,
    token,
    refCode,
    isInited,
    isReady,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
