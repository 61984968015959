import axios from 'axios'
import https from 'https'
import { authStore } from 'contexts'

const restApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_REST_API_URL || '/api',
  // httpsAgent: new https.Agent({ rejectUnauthorized: false, keepAlive: true }),
})

restApi.interceptors.request.use((config) => {
  const { token } = authStore.getSnapshot()

  if (token) {
    config.headers.authorization = `Bearer ${token}`
  }

  return config
})

restApi.interceptors.response.use((response) => {
  const { token } = authStore.getSnapshot()

  if (
    response.status === 401 &&
    token &&
    // remove token only if it's the same with request moment
    // it may be changed
    `Bearer ${token}` === response.config?.headers?.authorization
  ) {
    authStore.logout()
  }

  return response
})

export default restApi
