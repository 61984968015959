'use client'
import { isValidElement, cloneElement, useRef, useEffect, useState } from 'react'
import { useRouter } from 'next/navigation'
import { useConnectModal, ConnectButton } from '@rainbow-me/rainbowkit'
import { useAuthContext } from 'contexts'
import { buttonMessages, Button, type ButtonProps } from 'components/inputs'
import clsx from 'clsx'

type ConnectButtonWrapperProps = {
  connectViewProps?:
    | true
    | {
        className?: ButtonProps['className']
        title?: ButtonProps['title']
        leftIcon?: ButtonProps['leftIcon']
        size?: ButtonProps['size']
        styling?: ButtonProps['styling']
        onConnectClick?: ButtonProps['onClick']
      }
  withCallOnClickOnConnectSuccess?: boolean
}

const ConnectButtonWrapper: React.CFC<ConnectButtonWrapperProps> = (props) => {
  const { children, connectViewProps, withCallOnClickOnConnectSuccess = true } = props

  const { address, isReady, notVerifiedAddress } = useAuthContext()
  const { openConnectModal } = useConnectModal()
  const router = useRouter()
  const addressRef = useRef(address)
  const [isConnectClicked, setIsConnectClicked] = useState(false)

  useEffect(() => {
    if (isConnectClicked && address) {
      if (withCallOnClickOnConnectSuccess && isValidElement(children)) {
        children.props?.onClick?.()

        if (typeof children.props?.to === 'string') {
          router.push(children.props.to)
        }
      }
    }
    addressRef.current = address
  }, [address, isConnectClicked])

  const handleConnectClick: ButtonProps['onClick'] = (event) => {
    event.preventDefault()

    openConnectModal?.()
    setIsConnectClicked(true)

    if (typeof connectViewProps === 'object' && typeof connectViewProps?.onConnectClick === 'function') {
      connectViewProps.onConnectClick(event)
    }
  }

  if ((!isReady || !address) && connectViewProps) {
    const {
      className = 'lg:btn-size-big',
      title = buttonMessages.connect,
      leftIcon = 'ui/pig',
      size = 's',
      styling = 'main',
    } = typeof connectViewProps === 'object' ? connectViewProps : {}

    return (
      <ConnectButton.Custom>
        {({ openConnectModal, mounted }) => {
          return (
            <Button
              className={className}
              title={title}
              leftIcon={leftIcon}
              size={size}
              styling={styling}
              loading={!mounted || !isReady}
              loadingTitle={notVerifiedAddress ? buttonMessages.processing : buttonMessages.loading}
              onClick={openConnectModal}
            />
          )
        }}
      </ConnectButton.Custom>
    )
  }

  if (isValidElement(children)) {
    const { onClick } = children.props

    const handleClick = (event: any) => {
      if (!address) {
        return handleConnectClick(event)
      }

      if (typeof onClick === 'function') {
        onClick(event)
      }
    }

    return cloneElement(children, { ...children.props, onClick: handleClick })
  }

  return children
}

export default ConnectButtonWrapper
